import {
  ADMIN_DASHBOARD_SUMMARY,
  ADMIN_INVESTOR_LIST,
  ADMIN_INVESTMENT_LIST,
  CUSTOMER_LIST,
  ADMIN_TRANSACTION_LIST,
  RM_LIST,
  FAQ_LIST,
  FAQ_DETAIL,
  FAQ_DETAIL_RESET,
  SUPPORT_LIST,
  SUPPORT_SHOW,
  SUPPORT_DETAIL,
  SUPPORT_CLOSE,
  RM_ASSIGN_LIST,
  RM_DETAIL,
  CUSTOMER_STATUS_LIST,
  RM_BANK_LIST,
  ADMIN_INVESTOR_VIEW,
  RM_REFERRAL_CODE,
  RM_SUMMARY_REPORT,
  CUSTOMER_LIST_RM,
  ADMIN_INVESTOR_ADD,
  ADMIN_INVESTOR_DETAIL,
  ADMIN_RESUBMIT_PROFILE,
  ADMIN_ADD_INVESTOR_DATA,
  ADMIN_EDIT_INVESTOR_DATA,
  ADMIN_FATCA_DATA,
  ADMIN_FATCA_LINK,
  ADMIN_IIN_LINK,
  ADMIN_CHECK_BROKER,
  BANK_DETAIL_IFSC,
  INVESTOR_RESET,
  ADMIN_INVESTMENT_AMC,
  ADMIN_INVESTMENT_INVESTOR,
  ADMIN_INVESTMENT_FUND,
  ADMIN_INVESTMENT_CART_PRODUCT_BUY,
  ADMIN_INVESTMENT_CART_PRODUCT_DELETE,
  ADMIN_INVESTMENT_CART_PRODUCT_PROCEED,
  ADMIN_INVESTMENT_CART_PRODUCT_SHOW,
  ADMIN_INVESTMENT_CART_PRODUCT_ADD,
  ADMIN_DASHBOARD_AUM_SUMMARY
} from "../actions/types";

const initialState = {
  adminDashboardSummary: {},
  adminInvestorList: {},
  adminInvestmentList: {},
  adminTransactionList: {},
  customerList: {},
  rmList: {},
  faqListData: {},
  faqDetailData: {},
  supportListData: {},
  supportShowData: {},
  supportDetailData: {},
  supportClose: {},
  rmAssignListData: {},
  rmDetailData: {},
  customerStatusListData: {},
  rmBankListData: {},
  adminInvestorView: {},
  rmReferralCode: {},
  rmSummaryReport: {},
  customerListRM: {},
  adminInvestorAdd: {},
  adminInvestorDetail: {},
  adminResubmitProfile: {},
  adminAddInvestorData: {},
  adminEditInvestorData: {},
  adminFatcaData: {},
  adminFatcaLink: {},
  adminIinLink: {},
  adminCheckBroker: {},
  bankDetailIfsc: {},
  adminInvestmentAmc: {},
  adminInvestmentInvestor: {},
  adminInvestmentFund: {},
  adminInvestmentCartProductBuy: {},
  adminInvestmentCartProductDelete: {},
  adminInvestmentCartProductProceed: {},
  adminInvestmentCartProductShow: {},
  adminInvestmentCartProductAdd: {},
  adminDashboardAUMSummary : {},
  loading: false,
};

const adminListReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_DASHBOARD_SUMMARY:
      return {
        ...state,
        loading: false,
        adminDashboardSummary: action.payload,
      };
    case ADMIN_INVESTOR_LIST:
      return {
        ...state,
        loading: false,
        adminInvestorList: action.payload,
      };
    case ADMIN_INVESTMENT_LIST:
      return {
        ...state,
        loading: false,
        adminInvestmentList: action.payload,
      };
    case ADMIN_TRANSACTION_LIST:
      return {
        ...state,
        loading: false,
        adminTransactionList: action.payload,
      };
    case CUSTOMER_LIST:
      return {
        ...state,
        loading: false,
        customerList: action.payload,
      };
    case RM_LIST:
      return {
        ...state,
        loading: false,
        rmList: action.payload,
      };
    case FAQ_LIST:
      return {
        ...state,
        loading: false,
        faqListData: action.payload,
      };
    case FAQ_DETAIL:
      return {
        ...state,
        loading: false,
        faqDetailData: action.payload,
      };
    case SUPPORT_LIST:
      return {
        ...state,
        loading: false,
        supportListData: action.payload,
      };
    case SUPPORT_SHOW:
      return {
        ...state,
        supportShowData: action.payload,
      };
    case SUPPORT_DETAIL:
      return {
        ...state,
        loading: false,
        supportDetailData: action.payload,
      };
    case SUPPORT_CLOSE:
      return {
        ...state,
        supportShow: {},
        supportDetailData: {},
      };
    case RM_ASSIGN_LIST:
      return {
        ...state,
        rmAssignListData: action.payload,
      };
    case RM_DETAIL:
      return {
        ...state,
        rmDetailData: action.payload,
      };
    case CUSTOMER_STATUS_LIST:
      return {
        ...state,
        customerStatusListData: action.payload,
      };
    case RM_BANK_LIST:
      return {
        ...state,
        rmBankListData: action.payload,
      };
    case ADMIN_INVESTOR_VIEW:
      return {
        ...state,
        adminInvestorView: action.payload,
      };
    case RM_REFERRAL_CODE:
      return {
        ...state,
        rmReferralCode: action.payload,
      };
    case RM_SUMMARY_REPORT:
      return {
        ...state,
        rmSummaryReport: action.payload,
      };
    case CUSTOMER_LIST_RM:
      return {
        ...state,
        customerListRM: action.payload,
      };
    case ADMIN_INVESTOR_ADD:
      return {
        ...state,
        adminInvestorAdd: action.payload,
      };
    case ADMIN_EDIT_INVESTOR_DATA:
      return {
        ...state,
        adminEditInvestorData: action.payload,
      };
    case ADMIN_RESUBMIT_PROFILE:
      return {
        ...state,
        adminResubmitProfile: action.payload,
      };
    case ADMIN_ADD_INVESTOR_DATA:
      return {
        ...state,
        adminAddInvestorData: action.payload,
      };
    case ADMIN_FATCA_DATA:
      return {
        ...state,
        adminFatcaData: action.payload,
      };
    case ADMIN_FATCA_LINK:
      return {
        ...state,
        adminFatcaLink: action.payload,
      };
    case ADMIN_IIN_LINK:
      return {
        ...state,
        adminIinLink: action.payload,
      };
    case ADMIN_INVESTOR_DETAIL:
      return {
        ...state,
        adminInvestorDetail: action.payload,
      };
    case ADMIN_CHECK_BROKER:
      return {
        ...state,
        adminCheckBroker: action.payload,
      };
    case BANK_DETAIL_IFSC:
      return {
        ...state,
        bankDetailIfsc: action.payload,
      };
    case INVESTOR_RESET:
      return { ...state, adminEditInvestorData: {} };
    case ADMIN_INVESTMENT_AMC:
      return {
        ...state,
        adminInvestmentAmc: action.payload,
      };
    case ADMIN_INVESTMENT_INVESTOR:
      return {
        ...state,
        adminInvestmentInvestor: action.payload,
      };
    case ADMIN_INVESTMENT_FUND:
      return {
        ...state,
        adminInvestmentFund: action.payload,
      };
    case ADMIN_INVESTMENT_CART_PRODUCT_BUY:
      return {
        ...state,
        adminInvestmentCartProductBuy: action.payload,
      };
    case ADMIN_INVESTMENT_CART_PRODUCT_DELETE:
      return {
        ...state,
        adminInvestmentCartProductDelete: action.payload,
      };
    case ADMIN_INVESTMENT_CART_PRODUCT_PROCEED:
      return {
        ...state,
        adminInvestmentCartProductProceed: action.payload,
      };
    case ADMIN_INVESTMENT_CART_PRODUCT_SHOW:
      return {
        ...state,
        adminInvestmentCartProductShow: action.payload,
      };
    case ADMIN_INVESTMENT_CART_PRODUCT_ADD:
      return {
        ...state,
        adminInvestmentCartProductAdd: action.payload,
      };
      case ADMIN_DASHBOARD_AUM_SUMMARY:
        return {
         ...state,
          loading: false,
          adminDashboardAUMSummary: action.payload,
        };
    case FAQ_DETAIL_RESET:
      return { ...state, faqDetailData: {} };
    // Add more cases for other admin dashboard actions here...
    default:
      return state;
  }
};
export default adminListReducers;
